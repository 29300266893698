import axios, { AxiosRequestConfig } from 'axios';

// eslint-disable-next-line import/no-cycle
import { isValidToken } from 'src/auth/context/jwt/utils';
// eslint-disable-next-line import/no-cycle
import { KEY_accessToken } from 'src/auth/context/jwt/auth-provider';
import { HOST_API, HOST_API_PREFIX, HOST_API_VERSION } from 'src/config-global';
import { reject } from 'lodash';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API,
  // COOKIE HTTPONLY
  // withCredentials: true,
});

axiosInstance.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem(KEY_accessToken);

  if (accessToken && isValidToken(accessToken)) {
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
});

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => {
    console.error(error);
    return Promise.reject((error.response && error.response.data) || 'Something went wrong');
  }
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};
// ----------------------------------------------------------------------
// 
export const endpoints = {
  /* Admin Access ******************************************************************* */
  // User
  admin: {
    access: {
      user: {
        list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/access/users`,
        details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/access/users`,
        create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/access/users`,
        delete: `${HOST_API_PREFIX}/${HOST_API_VERSION}/access/users`,
        update: `${HOST_API_PREFIX}/${HOST_API_VERSION}/access/users`,
        change: {
          password: `${HOST_API_PREFIX}/${HOST_API_VERSION}/access/users/change/password`,
        },
        latest: `${HOST_API_PREFIX}/users/latest`,
        search: `${HOST_API_PREFIX}/users/search`,
        upload: `${HOST_API_PREFIX}/${HOST_API_VERSION}/access/users/upload`,
        listAll: `${HOST_API_PREFIX}/${HOST_API_VERSION}/access/users/all`,
        listAllWithFiltering: `${HOST_API_PREFIX}/${HOST_API_VERSION}/access/users/all/with-filtering`,

      },
      role: {
        listAll: `${HOST_API_PREFIX}/${HOST_API_VERSION}/access/roles/all`,
        list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/access/roles`,
        details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/access/roles`,
        create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/access/roles`,
        delete: `${HOST_API_PREFIX}/${HOST_API_VERSION}/access/roles`,
        update: `${HOST_API_PREFIX}/${HOST_API_VERSION}/access/roles`,
      },
      permission: {
        listAll: `${HOST_API_PREFIX}/${HOST_API_VERSION}/access/permissions/all`,
        list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/access/permissions`,
        details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/access/permissions`,
        create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/access/permissions`,
        delete: `${HOST_API_PREFIX}/${HOST_API_VERSION}/access/permissions`,
        update: `${HOST_API_PREFIX}/${HOST_API_VERSION}/access/permissions`,
      },
    },
  },
  /* Client ******************************************************************* */
  client: {
    client: {
      clientCategory: {
        list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/client-categories`,
        details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/client-categories`,
        create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/client-categories`,
        delete: `${HOST_API_PREFIX}/${HOST_API_VERSION}/client-categories`,
        update: `${HOST_API_PREFIX}/${HOST_API_VERSION}/client-categories`,
        listAll: `${HOST_API_PREFIX}/${HOST_API_VERSION}/client-categories/all`,
      },
      clientChain: {
        list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/client-chains`,
        details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/client-chains`,
        create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/client-chains`,
        delete: `${HOST_API_PREFIX}/${HOST_API_VERSION}/client-chains`,
        update: `${HOST_API_PREFIX}/${HOST_API_VERSION}/client-chains`,
        listAll: `${HOST_API_PREFIX}/${HOST_API_VERSION}/client-chains/all`,
      },
      clientPosition: {
        list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/client-positions`,
        details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/client-positions`,
        create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/client-positions`,
        delete: `${HOST_API_PREFIX}/${HOST_API_VERSION}/client-positions`,
        update: `${HOST_API_PREFIX}/${HOST_API_VERSION}/client-positions`,
        listAll: `${HOST_API_PREFIX}/${HOST_API_VERSION}/client-positions/all`,
      },
      list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/clients`,
      details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/clients`,
      create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/clients`,
      delete: `${HOST_API_PREFIX}/${HOST_API_VERSION}/clients`,
      update: `${HOST_API_PREFIX}/${HOST_API_VERSION}/clients`,
      listAll: `${HOST_API_PREFIX}/${HOST_API_VERSION}/clients/all`,
      addClient: `${HOST_API_PREFIX}/${HOST_API_VERSION}/clients/add-client`,
      // paySalesPaymentInvoices: `${HOST_API_PREFIX}${HOST_API_VERSION ? `/${HOST_API_VERSION}` :''}/clients/pay-sales-invoices`
    },
  },
  /* Company ******************************************************************* */

  company: {
    companyWarehouseProduct: {
      list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouse-products`,
      details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouse-products`,
      create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouse-products`,
      delete: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouse-products`,
      update: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouse-products`,
      listAll: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouse-products/all`,
      listAllByClient: (clientId: string) => `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouse-products/all/by-company-client/${clientId}`,
      transferToCompanyWarehouseDamagedProduct: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouse-products/transfer-to-company-warehouse-damaged-product`,
      transferToAnotherCompanyWarehouse: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouse-products/transfer-to-another-company-warehouse`,
      transferToAnotherVehicle: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouse-products/transfer-to-another-vehicle`,
    },
    companyWarehouseDamagedProduct: {
      list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouse-damaged-products`,
      details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouse-damaged-products`,
      create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouse-damaged-products`,
      delete: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouse-damaged-products`,
      update: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouse-damaged-products`,
      listAll: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouse-damaged-products/all`,
      listAllByClient: (clientId: string) => `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouse-damaged-products/all/by-company-client/${clientId}`,
      transferToAnotherCompanyWarehouse: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouse-damaged-products/transfer-to-another-company-warehouse`,
      transferToAnotherVehicle: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouse-products/transfer-to-another-vehicle`,
    },
    companyWarehouseDamagedProductCategory: {
      list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouse-damaged-product-categories`,
      details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouse-damaged-product-categories`,
      create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouse-damaged-product-categories`,
      delete: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouse-damaged-product-categories`,
      update: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouse-damaged-product-categories`,
      listAll: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouse-damaged-product-categories/all`,
    },
    company: {
      list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/companies`,
      details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/companies`,
      create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/companies`,
      delete: `${HOST_API_PREFIX}/${HOST_API_VERSION}/companies`,
      update: `${HOST_API_PREFIX}/${HOST_API_VERSION}/companies`,
      upload: `${HOST_API_PREFIX}/${HOST_API_VERSION}/companies/upload`,
    },
    companyWarehouse: {
      list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouses`,
      details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouses`,
      create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouses`,
      delete: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouses`,
      update: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouses`,
      listAll: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouses/all`,
      // user: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouses/user`,
    },
    companyClient: {
      list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-clients`,
      details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-clients`,
      create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-clients`,
      delete: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-clients`,
      update: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-clients`,
      listAll: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-clients/all`,
      addClient: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-clients/add-client/`,
      listAllByCompanyClientChainId: (clientChainId: string) => `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-clients/all/by-client-chain/${clientChainId}`,
      listAllBySalesPaymentParentId: (salesPaymentParentId: string) => `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-clients/all/by-sales-payment-parent/${salesPaymentParentId}`,
      generateAccountStatementPdf: (companyClientId: string, currency: string) => `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-clients/generate-account-statement-pdf/${companyClientId}?currencyCode=${currency}`,
    },
    companyClientCategory: {
      list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-client-categories`,
      details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-client-categories`,
      create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-client-categories`,
      delete: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-client-categories`,
      update: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-client-categories`,
      listAll: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-client-categories/all`,
    },
    companyClientChain: {
      list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-client-chains`,
      details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-client-chains`,
      create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-client-chains`,
      delete: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-client-chains`,
      update: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-client-chains`,
      listAll: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-client-chains/all`,
    },
    companyClientGroup: {
      list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-client-groups`,
      details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-client-groups`,
      create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-client-groups`,
      delete: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-client-groups`,
      update: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-client-groups`,
      listAll: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-client-groups/all`,
    },
    companyZone: {
      list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-zones`,
      details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-zones`,
      create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-zones`,
      delete: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-zones`,
      update: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-zones`,
      listAll: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-zones/all`,
    },
    companyPosition: {
      list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-positions`,
      details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-positions`,
      create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-positions`,
      delete: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-positions`,
      update: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-positions`,
      listAll: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-positions/all`,
    },
    companyClientPosition: {
      list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-client-positions`,
      details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-client-positions`,
      create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-client-positions`,
      delete: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-client-positions`,
      update: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-client-positions`,
      listAll: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-client-positions/all`,
    },
    companyWarehouseDamagedProductDisposeCategory: {
      list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouse-damaged-product-dispose-categories`,
      details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouse-damaged-product-dispose-categories`,
      create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouse-damaged-product-dispose-categories`,
      delete: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouse-damaged-product-dispose-categories`,
      update: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouse-damaged-product-dispose-categories`,
      listAll: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouse-damaged-product-dispose-categories/all`,
    },
    companyWarehouseDamagedProductRestoreCategory: {
      list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouse-damaged-product-restore-categories`,
      details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouse-damaged-product-restore-categories`,
      create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouse-damaged-product-restore-categories`,
      delete: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouse-damaged-product-restore-categories`,
      update: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouse-damaged-product-restore-categories`,
      listAll: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouse-damaged-product-restore-categories/all`,
    },
    companyWarehouseDamagedProductDispose: {
      list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouse-damaged-product-disposes`,
      details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouse-damaged-product-disposes`,
      listAll: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouse-damaged-product-disposes/all`,
      create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouse-damaged-product-disposes`,
      statusCounts: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouse-damaged-product-disposes/status-counts`,
      updateCompanyWarehouseDamagedProductDisposeStatus: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouse-damaged-product-disposes/status`, 
    },
    companyWarehouseDamagedProductRestore: {
      list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouse-damaged-product-restores`,
      details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouse-damaged-product-restores`,
      listAll: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouse-damaged-product-restores/all`,
      create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouse-damaged-product-restores`,
      statusCounts: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouse-damaged-product-restores/status-counts`,
      updateCompanyWarehouseDamagedProductRestoreStatus: `${HOST_API_PREFIX}/${HOST_API_VERSION}/company-warehouse-damaged-product-restores/status`, 
    },
  },
  /* CompanyEmployee ******************************************************************* */
  companyEmployee: {
  },

  /* Other ******************************************************************* */
  other: {
    currency: {
      list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/currencies`,
      details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/currencies`,
      create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/currencies`,
      delete: `${HOST_API_PREFIX}/${HOST_API_VERSION}/currencies`,
      update: `${HOST_API_PREFIX}/${HOST_API_VERSION}/currencies`,
      listAll: `${HOST_API_PREFIX}/${HOST_API_VERSION}/currencies/all`,
    },
    language: {
      list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/languages`,
      details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/languages`,
      create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/languages`,
      delete: `${HOST_API_PREFIX}/${HOST_API_VERSION}/languages`,
      update: `${HOST_API_PREFIX}/${HOST_API_VERSION}/languages`,
      listAll: `${HOST_API_PREFIX}/${HOST_API_VERSION}/languages/all`,
    },
    country: {
      list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/countries`,
      details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/countries`,
      create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/countries`,
      delete: `${HOST_API_PREFIX}/${HOST_API_VERSION}/countries`,
      update: `${HOST_API_PREFIX}/${HOST_API_VERSION}/countries`,
      byName: `${HOST_API_PREFIX}/${HOST_API_VERSION}/countries/name`,
      listAll: `${HOST_API_PREFIX}/${HOST_API_VERSION}/countries/all`,
    },
    city: {
      list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/cities`,
      details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/cities`,
      create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/cities`,
      delete: `${HOST_API_PREFIX}/${HOST_API_VERSION}/cities`,
      update: `${HOST_API_PREFIX}/${HOST_API_VERSION}/cities`,
    },
    region: {
      list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/regions`,
      details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/regions`,
      create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/regions`,
      delete: `${HOST_API_PREFIX}/${HOST_API_VERSION}/regions`,
      update: `${HOST_API_PREFIX}/${HOST_API_VERSION}/regions`,
      listAll: `${HOST_API_PREFIX}/${HOST_API_VERSION}/regions/all`,
    },

  },

  /* product ******************************************************************* */
  product: {
    productPackageName: {
      list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/product-package-names`,
      details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/product-package-names`,
      create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/product-package-names`,
      delete: `${HOST_API_PREFIX}/${HOST_API_VERSION}/product-package-names`,
      update: `${HOST_API_PREFIX}/${HOST_API_VERSION}/product-package-names`,
      listAll: `${HOST_API_PREFIX}/${HOST_API_VERSION}/product-package-names/all`,
      // upload: `${HOST_API_PREFIX}/${HOST_API_VERSION}/product-package-names/upload`,
    },
    productBrand: {
      list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/product-brands`,
      details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/product-brands`,
      create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/product-brands`,
      delete: `${HOST_API_PREFIX}/${HOST_API_VERSION}/product-brands`,
      update: `${HOST_API_PREFIX}/${HOST_API_VERSION}/product-brands`,
      listAll: `${HOST_API_PREFIX}/${HOST_API_VERSION}/product-brands/all`,
      upload: `${HOST_API_PREFIX}/${HOST_API_VERSION}/product-brands/upload`,
    },
    product: {
      productCategory: {
        list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/product-categories`,
        details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/product-categories`,
        create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/product-categories`,
        delete: `${HOST_API_PREFIX}/${HOST_API_VERSION}/product-categories`,
        update: `${HOST_API_PREFIX}/${HOST_API_VERSION}/product-categories`,
        listAll: `${HOST_API_PREFIX}/${HOST_API_VERSION}/product-categories/all`,
      },
      productSize: {
        productPackage: {
          list: (productId: string, productSizeId: string) => `${HOST_API_PREFIX}/${HOST_API_VERSION}/products/${productId}/product-sizes/${productSizeId}/product-packages`,
          details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/product-packages`,
          create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/product-packages`,
          delete: `${HOST_API_PREFIX}/${HOST_API_VERSION}/product-packages`,
          update: `${HOST_API_PREFIX}/${HOST_API_VERSION}/product-packages`,
          // listAll: `${HOST_API_PREFIX}${HOST_API_VERSION ? `/${HOST_API_VERSION}` :''}/product-packages/all`,
          listAllByProductSizeId: (productSizeId: string) => `${HOST_API_PREFIX}/${HOST_API_VERSION}/product-packages/all/by-product-size/${productSizeId}`,
        },
        productVariant: {
          list: (productId: string, productSizeId: string) => `${HOST_API_PREFIX}/${HOST_API_VERSION}/products/${productId}/product-sizes/${productSizeId}/product-variants`,
          details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/product-variants`,
          create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/product-variants`,
          delete: `${HOST_API_PREFIX}/${HOST_API_VERSION}/product-variants`,
          update: `${HOST_API_PREFIX}/${HOST_API_VERSION}/product-variants`,
          // listAll: `${HOST_API_PREFIX}${HOST_API_VERSION ? `/${HOST_API_VERSION}` :''}/product-variants/all`,
          upload: `${HOST_API_PREFIX}/${HOST_API_VERSION}/product-variants/upload`,
          uploadPackageBarcodePhoto: `${HOST_API_PREFIX}/${HOST_API_VERSION}/product-variants/package-barcode/upload`,
          listAllByProductSizeId: (productSizeId: string) => `${HOST_API_PREFIX}/${HOST_API_VERSION}/product-variants/all/by-product-size/${productSizeId}`,
          updateSellingPricesForVariants: `${HOST_API_PREFIX}/${HOST_API_VERSION}/product-variants/update-selling-prices`,
          byBarcode: (barcode: string) => `${HOST_API_PREFIX}/${HOST_API_VERSION}/product-variants/barcode/${barcode}`,
          listAll: `${HOST_API_PREFIX}/${HOST_API_VERSION}/product-variants/all`,

        },
        list: (productId: string) => `${HOST_API_PREFIX}/${HOST_API_VERSION}/products/${productId}/product-sizes`,
        details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/product-sizes`,
        create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/product-sizes`,
        delete: `${HOST_API_PREFIX}/${HOST_API_VERSION}/product-sizes`,
        update: `${HOST_API_PREFIX}/${HOST_API_VERSION}/product-sizes`,
        upload: `${HOST_API_PREFIX}/${HOST_API_VERSION}/product-sizes/upload`,
        listAllByProductId: (productId: string) => `${HOST_API_PREFIX}/${HOST_API_VERSION}/product-sizes/all/by-product/${productId}`,
        copyProductPackages: `${HOST_API_PREFIX}/${HOST_API_VERSION}/product-sizes/copy-product-packages`,
        copyProductVariants: `${HOST_API_PREFIX}/${HOST_API_VERSION}/product-sizes/copy-product-variants`,

      },
      list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/products`,
      details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/products`,
      create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/products`,
      delete: `${HOST_API_PREFIX}/${HOST_API_VERSION}/products`,
      update: `${HOST_API_PREFIX}/${HOST_API_VERSION}/products`,
      search: `${HOST_API_PREFIX}/${HOST_API_VERSION}/product/search`, // not used
      listAll: `${HOST_API_PREFIX}/${HOST_API_VERSION}/products/all`,
      upload: `${HOST_API_PREFIX}/${HOST_API_VERSION}/products/upload`,
      listAllByProductBrandId: (brandId: string) => `${HOST_API_PREFIX}/${HOST_API_VERSION}/products/all/by-product-brand/${brandId}`,
      detailsByCompanyClientId: (productId: string, companyClientId: string) => `${HOST_API_PREFIX}/${HOST_API_VERSION}/products/${productId}/by-company-client/${companyClientId}`,
      listAllByProductsBrandIdAndCompanyClientId: `${HOST_API_PREFIX}/${HOST_API_VERSION}/products/sales-invoice/by-company-client`,
      listAllByProductsBrandIdAndProviderWarehouseId: `${HOST_API_PREFIX}/${HOST_API_VERSION}/products/sales-invoice/by-provider-warehouse`,
      listForStock: `${HOST_API_PREFIX}/${HOST_API_VERSION}/products/stock`,
    },
  },
  /* provider ******************************************************************* */

  provider: {
    provider: {
      list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/providers`,
      details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/providers`,
      create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/providers`,
      delete: `${HOST_API_PREFIX}/${HOST_API_VERSION}/providers`,
      update: `${HOST_API_PREFIX}/${HOST_API_VERSION}/providers`,
      listAll: `${HOST_API_PREFIX}/${HOST_API_VERSION}/providers/all`,
      // listAllByProviderChainId: (chainId: string) => `${HOST_API_PREFIX}/${HOST_API_VERSION}/providers/all/by-provider-chain/${chainId}`,
      // listAllByPurchasePaymentParentId: (purchasePaymentParentId: string) => `${HOST_API_PREFIX}/${HOST_API_VERSION}/providers/all/by-purchase-payment-parent/${purchasePaymentParentId}`,

    },
    providerWarehouse: {
      list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/provider-warehouses`,
      details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/provider-warehouses`,
      create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/provider-warehouses`,
      delete: `${HOST_API_PREFIX}/${HOST_API_VERSION}/provider-warehouses`,
      update: `${HOST_API_PREFIX}/${HOST_API_VERSION}/provider-warehouses`,
      listAll: `${HOST_API_PREFIX}/${HOST_API_VERSION}/provider-warehouses/all`,
    },
  },
  /* salesInvoice ******************************************************************* */
  invoice: {
    report: {
      listAll: `${HOST_API_PREFIX}/${HOST_API_VERSION}/reports/product/product-variants`, 
      create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/reports`, 
    },
    salesInvoice: {
      list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/sales-invoices`,
      details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/sales-invoices`,
      create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/sales-invoices`,
      delete: `${HOST_API_PREFIX}/${HOST_API_VERSION}/sales-invoices`,
      reject: (id: string) => `${HOST_API_PREFIX}/${HOST_API_VERSION}/sales-invoices/${id}/reject`,
      cancel: (id: string) => `${HOST_API_PREFIX}/${HOST_API_VERSION}/sales-invoices/${id}/cancel`,
      archive: (id: string) => `${HOST_API_PREFIX}/${HOST_API_VERSION}/sales-invoices/${id}/archive`,
      switchIsEditableWhenPending: (id: string) => `${HOST_API_PREFIX}/${HOST_API_VERSION}/sales-invoices/${id}/switch-is-editable-when-pending`,
      update: `${HOST_API_PREFIX}/${HOST_API_VERSION}/sales-invoices`,
      search: `${HOST_API_PREFIX}/${HOST_API_VERSION}/sales-invoices/search`, // not used
      listAll: `${HOST_API_PREFIX}/${HOST_API_VERSION}/sales-invoices/all`,
      listAllForDeliveryTask: `${HOST_API_PREFIX}/${HOST_API_VERSION}/sales-invoices/all/for-delivery-task`,
      listAllByIds: `${HOST_API_PREFIX}/${HOST_API_VERSION}/sales-invoices/by-ids`,
      remainingBalances: `${HOST_API_PREFIX}/${HOST_API_VERSION}/sales-invoices/remaining-balances`,
      statusCounts: `${HOST_API_PREFIX}/${HOST_API_VERSION}/sales-invoices/status-counts`,
      debts: `${HOST_API_PREFIX}/${HOST_API_VERSION}/sales-invoices/debts/`,
      returns: `${HOST_API_PREFIX}/${HOST_API_VERSION}/sales-invoices/returns/`,

      updateDebtSalesInvoices: (companyClientId: string) => `${HOST_API_PREFIX}/${HOST_API_VERSION}/sales-invoices/debts/${companyClientId}`,
      updateSalesInvoiceStatus: `${HOST_API_PREFIX}/${HOST_API_VERSION}/sales-invoices/status`,
    },
    purchaseInvoice: {
      list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/purchase-invoices`,
      details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/purchase-invoices`,
      create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/purchase-invoices`,
      delete: `${HOST_API_PREFIX}/${HOST_API_VERSION}/purchase-invoices`,
      update: `${HOST_API_PREFIX}/${HOST_API_VERSION}/purchase-invoices`,
      search: `${HOST_API_PREFIX}/${HOST_API_VERSION}/purchase-invoices/search`, // not used
      listAll: `${HOST_API_PREFIX}/${HOST_API_VERSION}/purchase-invoices/all`,

      listAllByIds: `${HOST_API_PREFIX}/${HOST_API_VERSION}/purchase-invoices/by-ids`,
      statusCounts: `${HOST_API_PREFIX}/${HOST_API_VERSION}/purchase-invoices/status-counts`,
      remainingBalances: `${HOST_API_PREFIX}/${HOST_API_VERSION}/purchase-invoices/remaining-balances`,

      debts: `${HOST_API_PREFIX}/${HOST_API_VERSION}/purchase-invoices/debts/`,
      returns: `${HOST_API_PREFIX}/${HOST_API_VERSION}/purchase-invoices/returns/`,
      updateDebtPurchaseInvoices: (providerWarehouseId: string) => `${HOST_API_PREFIX}/${HOST_API_VERSION}/purchase-invoices/debts/${providerWarehouseId}`,

      reject: (id: string) => `${HOST_API_PREFIX}/${HOST_API_VERSION}/purchase-invoices/${id}/reject`,
      cancel: (id: string) => `${HOST_API_PREFIX}/${HOST_API_VERSION}/purchase-invoices/${id}/cancel`,
      archive: (id: string) => `${HOST_API_PREFIX}/${HOST_API_VERSION}/purchase-invoices/${id}/archive`,
      switchIsEditableWhenPending: (id: string) => `${HOST_API_PREFIX}/${HOST_API_VERSION}/purchase-invoices/${id}/switch-is-editable-when-pending`,
      updatePurchaseInvoiceStatus: `${HOST_API_PREFIX}/${HOST_API_VERSION}/purchase-invoices/status`,
      updatePurchaseInvoiceDeliveryStatus: `${HOST_API_PREFIX}/${HOST_API_VERSION}/purchase-invoices/delivery-status`,
    },
    returnInvoice: {
      list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/return-invoices`,
      details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/return-invoices`,
      create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/return-invoices`,
      delete: `${HOST_API_PREFIX}/${HOST_API_VERSION}/return-invoices`,
      update: `${HOST_API_PREFIX}/${HOST_API_VERSION}/return-invoices`,
      search: `${HOST_API_PREFIX}/${HOST_API_VERSION}/return-invoices/search`, // not used
      listAll: `${HOST_API_PREFIX}/${HOST_API_VERSION}/return-invoices/all`,

      listAllByIds: `${HOST_API_PREFIX}/${HOST_API_VERSION}/return-invoices/by-ids`,
      statusCounts: `${HOST_API_PREFIX}/${HOST_API_VERSION}/return-invoices/status-counts`,

      reject: (id: string) => `${HOST_API_PREFIX}/${HOST_API_VERSION}/return-invoices/${id}/reject`,
      cancel: (id: string) => `${HOST_API_PREFIX}/${HOST_API_VERSION}/return-invoices/${id}/cancel`,
      archive: (id: string) => `${HOST_API_PREFIX}/${HOST_API_VERSION}/return-invoices/${id}/archive`,
      switchIsEditableWhenPending: (id: string) => `${HOST_API_PREFIX}/${HOST_API_VERSION}/return-invoices/${id}/switch-is-editable-when-pending`,
      updateReturnInvoiceStatus: `${HOST_API_PREFIX}/${HOST_API_VERSION}/return-invoices/status`,
      returnInvoiceItems: `${HOST_API_PREFIX}/${HOST_API_VERSION}/return-invoices/return-invoice-items`,

    },
    providerReturnInvoice: {
      list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/provider-return-invoices`,
      details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/provider-return-invoices`,
      create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/provider-return-invoices`,
      delete: `${HOST_API_PREFIX}/${HOST_API_VERSION}/provider-return-invoices`,
      update: `${HOST_API_PREFIX}/${HOST_API_VERSION}/provider-return-invoices`,
      search: `${HOST_API_PREFIX}/${HOST_API_VERSION}/provider-return-invoices/search`, // not used
      listAll: `${HOST_API_PREFIX}/${HOST_API_VERSION}/provider-return-invoices/all`,

      listAllByIds: `${HOST_API_PREFIX}/${HOST_API_VERSION}/provider-return-invoices/by-ids`,
      statusCounts: `${HOST_API_PREFIX}/${HOST_API_VERSION}/provider-return-invoices/status-counts`,

      reject: (id: string) => `${HOST_API_PREFIX}/${HOST_API_VERSION}/provider-return-invoices/${id}/reject`,
      cancel: (id: string) => `${HOST_API_PREFIX}/${HOST_API_VERSION}/provider-return-invoices/${id}/cancel`,
      archive: (id: string) => `${HOST_API_PREFIX}/${HOST_API_VERSION}/provider-return-invoices/${id}/archive`,
      switchIsEditableWhenPending: (id: string) => `${HOST_API_PREFIX}/${HOST_API_VERSION}/provider-return-invoices/${id}/switch-is-editable-when-pending`,
      updateProviderReturnInvoiceStatus: `${HOST_API_PREFIX}/${HOST_API_VERSION}/provider-return-invoices/status`,
    },
    salesPaymentInvoice: {
      list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/sales-payment-invoices`,
      details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/sales-payment-invoices`,
      create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/sales-payment-invoices`,
      delete: `${HOST_API_PREFIX}/${HOST_API_VERSION}/sales-payment-invoices`,
      update: `${HOST_API_PREFIX}/${HOST_API_VERSION}/sales-payment-invoices`,
      search: `${HOST_API_PREFIX}/${HOST_API_VERSION}/sales-payment-invoices/search`, // not used
      listAll: `${HOST_API_PREFIX}/${HOST_API_VERSION}/sales-payment-invoices/all`,

      listAllByIds: `${HOST_API_PREFIX}/${HOST_API_VERSION}/sales-payment-invoices/by-ids`,
      statusCounts: `${HOST_API_PREFIX}/${HOST_API_VERSION}/sales-payment-invoices/status-counts`,

      reject: (id: string) => `${HOST_API_PREFIX}/${HOST_API_VERSION}/sales-payment-invoices/${id}/reject`,
      cancel: (id: string) => `${HOST_API_PREFIX}/${HOST_API_VERSION}/sales-payment-invoices/${id}/cancel`,
      archive: (id: string) => `${HOST_API_PREFIX}/${HOST_API_VERSION}/sales-payment-invoices/${id}/archive`,
      switchIsEditableWhenPending: (id: string) => `${HOST_API_PREFIX}/${HOST_API_VERSION}/sales-payment-invoices/${id}/switch-is-editable-when-pending`,
      updateSalesPaymentInvoiceStatus: `${HOST_API_PREFIX}/${HOST_API_VERSION}/sales-payment-invoices/status`,
    },
    purchasePaymentInvoice: {
      list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/purchase-payment-invoices`,
      details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/purchase-payment-invoices`,
      create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/purchase-payment-invoices`,
      delete: `${HOST_API_PREFIX}/${HOST_API_VERSION}/purchase-payment-invoices`,
      update: `${HOST_API_PREFIX}/${HOST_API_VERSION}/purchase-payment-invoices`,
      search: `${HOST_API_PREFIX}/${HOST_API_VERSION}/purchase-payment-invoices/search`, // not used
      listAll: `${HOST_API_PREFIX}/${HOST_API_VERSION}/purchase-payment-invoices/all`,

      listAllByIds: `${HOST_API_PREFIX}/${HOST_API_VERSION}/purchase-payment-invoices/by-ids`,
      statusCounts: `${HOST_API_PREFIX}/${HOST_API_VERSION}/purchase-payment-invoices/status-counts`,

      reject: (id: string) => `${HOST_API_PREFIX}/${HOST_API_VERSION}/purchase-payment-invoices/${id}/reject`,
      cancel: (id: string) => `${HOST_API_PREFIX}/${HOST_API_VERSION}/purchase-payment-invoices/${id}/cancel`,
      archive: (id: string) => `${HOST_API_PREFIX}/${HOST_API_VERSION}/purchase-payment-invoices/${id}/archive`,
      switchIsEditableWhenPending: (id: string) => `${HOST_API_PREFIX}/${HOST_API_VERSION}/purchase-payment-invoices/${id}/switch-is-editable-when-pending`,
      updatePurchasePaymentInvoiceStatus: `${HOST_API_PREFIX}/${HOST_API_VERSION}/purchase-payment-invoices/status`,
    },
    salesPaymentInvoiceAddonCategory: {
      list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/sales-payment-invoice-addon-categories`,
      details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/sales-payment-invoice-addon-categories`,
      create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/sales-payment-invoice-addon-categories`,
      delete: `${HOST_API_PREFIX}/${HOST_API_VERSION}/sales-payment-invoice-addon-categories`,
      update: `${HOST_API_PREFIX}/${HOST_API_VERSION}/sales-payment-invoice-addon-categories`,
      listAll: `${HOST_API_PREFIX}/${HOST_API_VERSION}/sales-payment-invoice-addon-categories/all`,
    },
    purchasePaymentInvoiceAddonCategory: {
      list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/purchase-payment-invoice-addon-categories`,
      details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/purchase-payment-invoice-addon-categories`,
      create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/purchase-payment-invoice-addon-categories`,
      delete: `${HOST_API_PREFIX}/${HOST_API_VERSION}/purchase-payment-invoice-addon-categories`,
      update: `${HOST_API_PREFIX}/${HOST_API_VERSION}/purchase-payment-invoice-addon-categories`,
      listAll: `${HOST_API_PREFIX}/${HOST_API_VERSION}/purchase-payment-invoice-addon-categories/all`,
    },
  },
  /* work day ******************************************************************* */
  workDay: {
    workDayEndVisitCategory: {
      list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/work-day-end-visit-categories`,
      details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/work-day-end-visit-categories`,
      create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/work-day-end-visit-categories`,
      delete: `${HOST_API_PREFIX}/${HOST_API_VERSION}/work-day-end-visit-categories`,
      update: `${HOST_API_PREFIX}/${HOST_API_VERSION}/work-day-end-visit-categories`,
      listAll: `${HOST_API_PREFIX}/${HOST_API_VERSION}/work-day-end-visit-categories/all`,
    },
    workDayPauseCategory: {
      list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/work-day-pause-categories`,
      details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/work-day-pause-categories`,
      create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/work-day-pause-categories`,
      delete: `${HOST_API_PREFIX}/${HOST_API_VERSION}/work-day-pause-categories`,
      update: `${HOST_API_PREFIX}/${HOST_API_VERSION}/work-day-pause-categories`,
      listAll: `${HOST_API_PREFIX}/${HOST_API_VERSION}/work-day-pause-categories/all`,
    },
    list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/work-days`,
    details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/work-days`,
    create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/work-days`,
    delete: `${HOST_API_PREFIX}/${HOST_API_VERSION}/work-days`,
    update: `${HOST_API_PREFIX}/${HOST_API_VERSION}/work-days`,
    listAll: `${HOST_API_PREFIX}/${HOST_API_VERSION}/work-days/all`,

  },



  /* Other ******************************************************************* */
  vehicle: {
    vehicleBrand: {
      list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/vehicle-brands`,
      details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/vehicle-brands`,
      create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/vehicle-brands`,
      delete: `${HOST_API_PREFIX}/${HOST_API_VERSION}/vehicle-brands`,
      update: `${HOST_API_PREFIX}/${HOST_API_VERSION}/vehicle-brands`,
      byName: `${HOST_API_PREFIX}/${HOST_API_VERSION}/vehicle-brands/name`,
      listAll: `${HOST_API_PREFIX}/${HOST_API_VERSION}/vehicle-brands/all`,
    },
    vehicleModel: {
      list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/vehicle-models`,
      details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/vehicle-models`,
      create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/vehicle-models`,
      delete: `${HOST_API_PREFIX}/${HOST_API_VERSION}/vehicle-models`,
      update: `${HOST_API_PREFIX}/${HOST_API_VERSION}/vehicle-models`,
      listAll: `${HOST_API_PREFIX}/${HOST_API_VERSION}/vehicle-models/all`,
    },
    vehicleExtraChange: {
      list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/vehicle-extra-changes`,
      details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/vehicle-extra-changes`,
      listAll: `${HOST_API_PREFIX}/${HOST_API_VERSION}/vehicle-extra-changes/all`,
      create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/vehicle-extra-changes`,
      statusCounts: `${HOST_API_PREFIX}/${HOST_API_VERSION}/vehicle-extra-changes/status-counts`,
      updateVehicleExtraChangeStatus: `${HOST_API_PREFIX}/${HOST_API_VERSION}/vehicle-extra-changes/status`, 
    },
    list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/vehicles`,
    details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/vehicles`,
    create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/vehicles`,
    delete: `${HOST_API_PREFIX}/${HOST_API_VERSION}/vehicles`,
    update: `${HOST_API_PREFIX}/${HOST_API_VERSION}/vehicles`,
    listAll: `${HOST_API_PREFIX}/${HOST_API_VERSION}/vehicles/all`,
    upload: `${HOST_API_PREFIX}/${HOST_API_VERSION}/vehicles/upload`,
    user: `${HOST_API_PREFIX}/${HOST_API_VERSION}/vehicles  /user`,
  },

  /* finance ******************************************************************* */
  finance: {
    exchangeRate: {
      list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/exchange-rates`,
      details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/exchange-rates`,
      create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/exchange-rates`,
      delete: `${HOST_API_PREFIX}/${HOST_API_VERSION}/exchange-rates`,
      update: `${HOST_API_PREFIX}/${HOST_API_VERSION}/exchange-rates`,
      actives: `${HOST_API_PREFIX}/${HOST_API_VERSION}/exchange-rates/actives`,
      updateActiveExchangeRates: `${HOST_API_PREFIX}/${HOST_API_VERSION}/exchange-rates/update-actives`,
    },
    balanceChangeLog: {
      list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/balance-change-logs`,
      details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/balance-change-logs`,
      create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/balance-change-logs`,
      delete: `${HOST_API_PREFIX}/${HOST_API_VERSION}/balance-change-logs`,
      update: `${HOST_API_PREFIX}/${HOST_API_VERSION}/balance-change-logs`,
      listAll: `${HOST_API_PREFIX}/${HOST_API_VERSION}/balance-change-logs/all`,
    },
  },

  /* task ******************************************************************* */
  task: {
    deliveryTask: {
      list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/delivery-tasks`,
      details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/delivery-tasks`,
      detailsForEdit: (id: string) => `${HOST_API_PREFIX}/${HOST_API_VERSION}/delivery-tasks/${id}/for-edit`,
      createOrUpdate: `${HOST_API_PREFIX}/${HOST_API_VERSION}/delivery-tasks`,
      delete: `${HOST_API_PREFIX}/${HOST_API_VERSION}/delivery-tasks`,
      update: `${HOST_API_PREFIX}/${HOST_API_VERSION}/delivery-tasks`,
      listAll: `${HOST_API_PREFIX}/${HOST_API_VERSION}/delivery-tasks/all`,
      createFromSalesInvoices: `${HOST_API_PREFIX}/${HOST_API_VERSION}/delivery-tasks/create-from-sales-invoices`,
      deliveryTaskItems: `${HOST_API_PREFIX}/${HOST_API_VERSION}/delivery-tasks/delivery-task-items`,
      assignToUser: `${HOST_API_PREFIX}/${HOST_API_VERSION}/delivery-tasks/assign-to-user`,
      statusCounts: `${HOST_API_PREFIX}/${HOST_API_VERSION}/delivery-tasks/status-counts`,
      reorder: `${HOST_API_PREFIX}/${HOST_API_VERSION}/delivery-tasks/reorder`,
    },
    deliveryTaskReturnCategory: {
      list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/delivery-task-return-categories`,
      details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/delivery-task-return-categories`,
      create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/delivery-task-return-categories`,
      delete: `${HOST_API_PREFIX}/${HOST_API_VERSION}/delivery-task-return-categories`,
      update: `${HOST_API_PREFIX}/${HOST_API_VERSION}/delivery-task-return-categories`,
      listAll: `${HOST_API_PREFIX}/${HOST_API_VERSION}/delivery-task-return-categories/all`,
    },
    deliveryTaskItemReturnCategory: {
      list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/delivery-task-item-return-categories`,
      details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/delivery-task-item-return-categories`,
      create: `${HOST_API_PREFIX}/${HOST_API_VERSION}/delivery-task-item-return-categories`,
      delete: `${HOST_API_PREFIX}/${HOST_API_VERSION}/delivery-task-item-return-categories`,
      update: `${HOST_API_PREFIX}/${HOST_API_VERSION}/delivery-task-item-return-categories`,
      listAll: `${HOST_API_PREFIX}/${HOST_API_VERSION}/delivery-task-item-return-categories/all`,
    },
  },


  /*  ******************************************************************* */

  chat: `${HOST_API_PREFIX}/chat`,
  kanban: `${HOST_API_PREFIX}/kanban`,
  calendar: `${HOST_API_PREFIX}/calendar`,
  auth: {
    me: `${HOST_API_PREFIX}/${HOST_API_VERSION}/auth/me`,
    login: `${HOST_API_PREFIX}/${HOST_API_VERSION}/auth/login`,
    register: `${HOST_API_PREFIX}/${HOST_API_VERSION}/auth/register`,
  },
  mail: {
    list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/mail/list`,
    details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/mail/details`,
    labels: `${HOST_API_PREFIX}/${HOST_API_VERSION}/mail/labels`,
  },
  post: {
    list: `${HOST_API_PREFIX}/${HOST_API_VERSION}/post/list`,
    details: `${HOST_API_PREFIX}/${HOST_API_VERSION}/post/details`,
    latest: `${HOST_API_PREFIX}/${HOST_API_VERSION}/post/latest`,
    search: `${HOST_API_PREFIX}/${HOST_API_VERSION}/post/search`,
  },


};
